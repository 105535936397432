import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay, Flex,
  Grid,
  Icon,
  IconButton,
  Skeleton,
  Stack,
} from '@chakra-ui/react'
import { useAtomValue } from 'jotai'
import { paymentsAtom } from '../atoms/atoms'
import { EStatus, Payment } from '../api/api.contract'
import React, { useState } from 'react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { numberWithSpaces } from '../utils/numberWithSpaces'
import { api } from '../api'
import { usePayments } from '../hooks/usePayments'

interface BankItemProps {
  imageSrc: string
  label: string
  url: string
}

const BankItem: React.FC<BankItemProps> = ({ imageSrc, label, url }) => {
  const _url = url.replace('https://', '')

  const handleClick = () => {
    window.open(_url, '_blank')
  }

  return (
    <Grid templateColumns="50px 1fr" gap="14px" alignItems="center" onClick={handleClick}>
      <Box
        backgroundImage={imageSrc}
        backgroundColor="gray.600"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        boxSize="50px"
        borderRadius="10px"
        gap="18px"
      />
      <Box>{label}</Box>
    </Grid>
  )
}

interface BankDrawerProps {
  isOpen: boolean
  onClose: () => void
  url: string | null
}

const BankDrawer: React.FC<BankDrawerProps> = ({ isOpen, url, onClose }) => {
  const handleClick = () => {
    if (!url) return
    window.open(url, '_blank')
  }
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody py="30px">
          <Stack spacing="22px">
            <Stack spacing="14px">
              <BankItem
                imageSrc="https://qr.nspk.ru/proxyapp/logo/bank100000000004.png"
                url={`bank100000000004://${url}`}
                label="Тинькофф Банк"
              />
              <BankItem
                imageSrc="	https://qr.nspk.ru/proxyapp/logo/bank100000000111.png"
                url={`bank100000000111://${url}`}
                label="Сбербанк"
              />
            </Stack>
            <Button onClick={handleClick}>Открыть ссылку</Button>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

interface ItemProps {
  data: Payment
  onPayClick: (e: string) => void
}

const Item: React.FC<ItemProps> = ({ data, onPayClick }) => {
  const { fetchPayments } = usePayments()
  const [isLoading, setIsLoading] = useState(false)

  const statusText = {
    [EStatus.PENDING]: 'Ожидание оплаты',
    [EStatus.SUCCESS]: 'Оплачено',
    [EStatus.FAILED]: 'Отказано',
  }

  const statusColor = {
    [EStatus.PENDING]: 'orange.500',
    [EStatus.SUCCESS]: 'green.500',
    [EStatus.FAILED]: 'gray.500',
  }

  const handleChangeStatus = async (status: EStatus) => {
    try {
      if (!data.id) return
      setIsLoading(true)
      await api.savePayment(data.id, {
        ...data,
        status,
      })
      await fetchPayments()
    } catch (e) {
      alert('Ошибка оплаты')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid width="100%" borderRadius="14px" backgroundColor="gray.700" padding="14px 20px">
      <Stack spacing="16px">
        <Stack spacing="6px" lineHeight="1.1">
          <Box fontSize="0.9rem" color="gray.500">
            {moment(data.createdAt).format('DD.MM.YYYY HH:mm')}
          </Box>
          {data?.amount && (
            <Box fontSize="1.5rem" fontWeight="800">
              {numberWithSpaces(Number(data.amount))} ₽
            </Box>
          )}
          <Box color={statusColor[data.status]} fontSize="0.9rem">
            {statusText[data.status]}
          </Box>
        </Stack>

        {data?.status === EStatus.PENDING && (
          <Stack spacing="10px">
            {isLoading ? (
              <Skeleton height="40px" borderRadius="full" />
            ) : (
              <Button borderRadius="full" size="md" onClick={() => window.open(data.data, '_blank')}>
                Оплатить
              </Button>
            )}
            <Grid width="100%" templateColumns="1fr 1fr" gap="10px">
              {isLoading ? (
                <>
                  <Skeleton height="32px" borderRadius="full" />
                  <Skeleton height="32px" borderRadius="full" />
                </>
              ) : (
                <>
                  <Button
                    borderRadius="full"
                    size="sm"
                    onClick={() => handleChangeStatus(EStatus.SUCCESS)}
                  >
                    Уже оплатил
                  </Button>
                  <Button
                    borderRadius="full"
                    size="sm"
                    onClick={() => handleChangeStatus(EStatus.FAILED)}
                  >
                    Не буду платить
                  </Button>
                </>
              )}
            </Grid>
          </Stack>
        )}
      </Stack>
    </Grid>
  )
}

export const Payments = () => {
  const navigation = useNavigate()
  const payments = useAtomValue(paymentsAtom)

  const [bankUrl, setBankUrl] = useState<string | null>(null)
  const isOpenDrawer = !!bankUrl

  const handleClose = () => {
    navigation('/')
  }

  const handleOpenDrawer = (e: string) => {
    setBankUrl(e)
  }

  const handleCloseDrawer = () => {
    setBankUrl(null)
  }

  return (
    <>
      <Stack spacing="24px" pb="60px">
        <Flex position="sticky" top="0px" backgroundColor="gray.800" py="10px">
          <Center position="relative" width="100%">
            <IconButton
              isRound
              aria-label="close"
              icon={<Icon as={ArrowBackIcon} boxSize="20px" />}
              onClick={handleClose}
              colorScheme="blackAlpha"
              color="white"
              position="absolute"
              left="0px"
              size="sm"
              zIndex={2}
            />
            <Box fontWeight={800}>Запрос от ребенка</Box>
          </Center>
        </Flex>
        <Stack spacing="18px">
          {payments?.map((item) => <Item onPayClick={handleOpenDrawer} data={item} />)}
        </Stack>
      </Stack>
      <BankDrawer url={bankUrl} isOpen={isOpenDrawer} onClose={handleCloseDrawer} />
    </>
  )
}
