import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const secondary = defineStyle({
  background: 'white',
  border: 'none',
  color: 'black',
  _hover: {
    backgroundColor: 'blackAlpha.200',
  },
  _active: {
    backgroundColor: 'blackAlpha.300',
  },
})

export const buttonTheme = defineStyleConfig({
  variants: { secondary },
})
