import jsQR from 'jsqr'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Stack,
} from '@chakra-ui/react'
import xs from 'xstream'
import { useNavigate } from 'react-router-dom'
import { useWindowHeight } from '../hooks/useWindowHeight'
import { numberWithSpaces } from '../utils/numberWithSpaces'
import { CloseIcon } from '@chakra-ui/icons'
import { useQRMeta } from '../hooks/useQRMeta'
import { api } from '../api'
import { EStatus } from '../api/api.contract'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { EStepScan, paymentsAtom, phoneAtom, stepScanAtom } from '../atoms/atoms'
import { Emoji } from 'emoji-picker-react'

const StepScan = () => {
  return (
    <Stack
      spacing="14px"
      flex={1}
      borderRadius="18px"
      backgroundColor="gray.700"
      padding="20px 20px 30px 20px"
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        spacing="20px"
        flex={1}
      >
        <Emoji unified="1f6cd-fe0f" size={45} />
        <Box>Наведи камеру на QR код СБП</Box>
      </Stack>
    </Stack>
  )
}

interface StepCreatingProps {
  amount: string | null
  onRetry: () => void
  onStart: () => void
}

const StepCreating: React.FC<StepCreatingProps> = ({ amount, onRetry, onStart }) => {
  return (
    <Stack
      width="100%"
      spacing="24px"
      flex={1}
      justifyContent="center"
      borderRadius="18px"
      backgroundColor="gray.700"
      padding="20px 20px 30px 20px"
    >
      <Center flex={1} fontSize="2.2rem" textAlign="center" fontWeight="800">
        {numberWithSpaces(Number(amount))} ₽
      </Center>
      <Stack width="100%" spacing="24px">
        <Button size="lg" onClick={onStart} colorScheme="green" borderRadius="full">
          Папа, купи!
        </Button>
        <Button
          size="md"
          variant="link"
          onClick={onRetry}
          colorScheme="gray"
          borderRadius="full"
        >
          Еще раз
        </Button>
      </Stack>
    </Stack>
  )
}

const StepWaiting = () => {
  const setStepScan = useSetAtom(stepScanAtom)

  const handleClick = () => {
    setStepScan({
      scanData: null,
      step: EStepScan.SCAN,
      idPayment: null,
    })
  }

  return (
    <Stack
      spacing="14px"
      flex={1}
      borderRadius="18px"
      backgroundColor="gray.700"
      padding="20px 20px 30px 20px"
    >
      <Box textAlign="center">Ждем, что папа скажет</Box>
      <Center flex={1} position="relative">
        <Box position="absolute" zIndex={2}>
          <Emoji unified="1f644" size={38} />
        </Box>
        <Spinner color="gray.600" boxSize="86px" speed="3s" thickness="4px" />
      </Center>
      <Button size="md" onClick={handleClick} colorScheme="gray" borderRadius="full">
        Уже не надо
      </Button>
    </Stack>
  )
}

const StepResult = () => {
  const payments = useAtomValue(paymentsAtom)
  const [stepScan, setStepScan] = useAtom(stepScanAtom)

  const payment = useMemo(() => {
    return payments.find((p) => p.id === stepScan.idPayment)
  }, [payments, stepScan.idPayment])

  const handleClick = () => {
    setStepScan({
      scanData: null,
      step: EStepScan.SCAN,
      idPayment: null,
    })
  }

  return (
    <Stack
      spacing="14px"
      flex={1}
      borderRadius="18px"
      backgroundColor="gray.700"
      padding="20px 20px 30px 20px"
    >
      <Box textAlign="center">
        {payment?.status === EStatus.SUCCESS ? (
          <>Папа купил!</>
        ) : (
          <>Папа отказал</>
        )}
      </Box>
      <Center flex={1} position="relative">
        <Box position="absolute" zIndex={2}>
          {payment?.status === EStatus.SUCCESS ? (
            <Emoji unified="1f973" size={55} />
          ) : (
            <Emoji unified="1f614" size={55} />
          )}
        </Box>
      </Center>
      <Button size="md" onClick={handleClick} colorScheme="gray" borderRadius="full">
        {payment?.status === EStatus.SUCCESS ? (
          <>Отлично</>
        ) : (
          <>Жаль</>
        )}
      </Button>
    </Stack>
  )
}

interface QRScannerProps {}

export const QRScanner: React.FC<QRScannerProps> = ({}) => {
  const navigate = useNavigate()
  const { videoHeight } = useWindowHeight()
  const phone = useAtomValue(phoneAtom)

  const [stepScan, setStepScan] = useAtom(stepScanAtom)

  const { checkUrl, amount, typePayment } = useQRMeta(stepScan?.scanData)

  const [streamState, setStreamState] = useState<any>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  const data$ = xs.create({
    start: (listener) => {
      const tick = () => {
        const video = videoRef.current
        const canvasElement = document.getElementById('canvas') as HTMLCanvasElement
        const canvas = canvasElement?.getContext('2d')

        if (!canvas || !video) return

        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          canvasElement.height = video.videoHeight
          canvasElement.width = video.videoWidth
          canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height)

          const imageData = canvas.getImageData(
            0,
            0,
            canvasElement.width,
            canvasElement.height
          )

          const code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: 'dontInvert',
          })

          if (code) {
            if (checkUrl(code.data)) {
              listener.next(code.data)
            } else {
              //alert(code.data)
            }
          } else {
            listener.next('')
          }
        }
        setTimeout(() => requestAnimationFrame(tick), 300)
      }
      requestAnimationFrame(tick)
    },
    stop: () => {
      console.log('stop')
    },
  })

  const initialVideoStart = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: 'environment',
          aspectRatio: 1,
          height: window.innerWidth,
          width: window.innerWidth,
        },
      })
      .then(function (stream) {
        const video = videoRef.current
        if (!video) return
        video.srcObject = stream
        video.setAttribute('playsinline', 'true')

        video
          .play()
          .then(() => {})
          .catch((e) => console.log(e))
      })
  }

  const stop = () => {
    const video = videoRef.current
    if (!video) return
    video.pause()
    const stream = video.srcObject as MediaStream
    if (stream) {
      const tracks = stream.getTracks()
      tracks.forEach((track) => track.stop())
    }
    video.srcObject = null
  }

  const close = () => {
    stop()
    navigate('/')
  }

  const handleRetry = () => {
    setStepScan({
      scanData: null,
      step: EStepScan.SCAN,
      idPayment: null,
    })
  }

  const handleCreatePayment = async () => {
    try {
      if (!stepScan.scanData || !phone) return
      setStepScan((e) => ({
        ...e,
        step: EStepScan.WAITING,
        idPayment: null,
      }))
      const data = await api.createPayment({
        data: stepScan.scanData,
        amount: Number(amount),
        status: EStatus.PENDING,
        phone,
      })
      if (data?.id) {
        setStepScan((e) => ({
          ...e,
          idPayment: data?.id,
        }))
      }
    } catch (e) {}
  }

  useEffect(() => {
    setStreamState(
      data$.subscribe({
        next: (data) => {
          if (data !== '') {
            setStepScan((e) => ({
              scanData: String(data),
              step: EStepScan.CREATING,
              idPayment: null,
            }))
          }
        },
        complete: () => {
          stop()
        },
      })
    )
  }, [])

  useEffect(() => {
    initialVideoStart()
    return () => {
      if (streamState) {
        streamState.unsubscribe()
      }
    }
  }, [])

  return (
    <>
      <Stack flex={1} spacing="20px" width="100%" justifyContent="space-between">
        <Flex
          height={`${videoHeight}px`}
          position="relative"
          borderRadius="18px"
          overflow="hidden"
          borderWidth={2}
          borderColor={stepScan.step !== EStepScan.SCAN ? 'green.400' : 'transparent'}
        >
          <Center
            position="absolute"
            left={0}
            top={0}
            right={0}
            bottom={0}
            width="100%"
            zIndex={2}
            height="100%"
          >
            <IconButton
              isRound
              aria-label="close"
              icon={<Icon as={CloseIcon} />}
              onClick={close}
              colorScheme="blackAlpha"
              color="white"
              position="absolute"
              right="16px"
              top="16px"
              size="sm"
              zIndex={2}
            />
          </Center>
          <Box
            position="relative"
            transition="0.4s"
            opacity={stepScan.step !== EStepScan.SCAN ? 'green.400' : 'transparent'}
            overflow="hidden"
          >
            <video ref={videoRef} />
          </Box>
        </Flex>
        {stepScan.step === EStepScan.SCAN && <StepScan />}
        {stepScan.step === EStepScan.CREATING && (
          <StepCreating
            amount={amount}
            onRetry={handleRetry}
            onStart={handleCreatePayment}
          />
        )}
        {stepScan.step === EStepScan.WAITING && <StepWaiting />}
        {stepScan.step === EStepScan.RESULT && <StepResult />}
      </Stack>
      <Box display="none">
        <canvas id="canvas" />
      </Box>
    </>
  )
}
