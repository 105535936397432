export enum EStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export type Payment = {
  id?: string
  createdAt?: string
  data: string
  amount: number
  description?: string
  status: EStatus
  phone: string
}

export interface CreatePaymentRequest extends Payment {}
