import { Flex, FlexProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { useWindowHeight } from '../../hooks/useWindowHeight'

interface ContainerProps extends FlexProps{
  children: ReactNode
}

export const Container: React.FC<ContainerProps> = ({ children, ...props }) => {
  const { height } = useWindowHeight()

  return (
    <Flex
      flexDirection="column"
      p="20px"
      maxW="460px"
      ml="auto"
      mr="auto"
      flex={1}
      width="100%"
      height={`${height}px`}
      maxH="1000px"
      {...props}
    >
      {children}
    </Flex>
  )
}
