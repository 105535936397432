import React, { useEffect, useLayoutEffect } from 'react'
import { QRScanner } from './routes/QRScanner'
import { useColorMode } from '@chakra-ui/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Main } from './routes/Main'
import { Container } from './components/Container'
import { useAtom, useAtomValue } from 'jotai'
import { EStepScan, paymentsAtom, phoneAtom, stepScanAtom } from './atoms/atoms'
import { usePayments } from './hooks/usePayments'
import { Payments } from './routes/Payments'
import { EStatus } from './api/api.contract'

function App() {
  const { setColorMode } = useColorMode()
  const { fetchPayments } = usePayments()
  const [phone, setPhone] = useAtom(phoneAtom)
  const payments = useAtomValue(paymentsAtom)
  const [stepScan, setStepScan] = useAtom(stepScanAtom)

  useEffect(() => {
    const phoneStorage = localStorage.getItem('phone')
    if (phoneStorage) {
      setPhone(phoneStorage)
    }
  }, [])

  useEffect(() => {
    fetchPayments()
    const intervalId = setInterval(fetchPayments, 3000)
    return () => clearInterval(intervalId);
  }, [phone])

  useEffect(() => {
    if (stepScan.idPayment) {
      const payment = payments.find(p => p.id === stepScan.idPayment)
      if (payment) {
        if (payment.status === EStatus.SUCCESS || payment.status === EStatus.FAILED) {
          setStepScan(e => ({
            ...e,
            step: EStepScan.RESULT
          }))
        }
      }
    }
  }, [payments, stepScan.idPayment])

  useLayoutEffect(() => {
    setColorMode('dark')
  }, [])

  return (
    <>
      <BrowserRouter>
        <Container>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="scan" element={<QRScanner />} />
            <Route path="payments" element={<Payments />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </>
  )
}

export default App
