import axios from 'axios'
import { Payment } from './api.contract'
export const baseURL = '/api'

export const api = Object.freeze({
  async fetchPayments(phone: string) {
    const response = await axios.get<Payment[]>(`${baseURL}/payments?phone=${phone}`)
    return response.data
  },
  async createPayment(data: Payment) {
    const response = await axios.post(`${baseURL}/payments`, data)
    return response.data
  },
  async savePayment(id: string, data: Payment) {
    const response = await axios.post(`${baseURL}/payments/${id}`, data)
    return response.data
  },
})
