import { atom } from 'jotai'
import { Payment } from '../api/api.contract'

export enum EStepScan {
  SCAN = 'SCAN',
  CREATING = 'CREATING',
  WAITING = 'WAITING',
  RESULT = 'RESULT',
}

type StepScanAtom = {
  step: EStepScan
  idPayment: string | null
  scanData: string | null
}

export const phoneAtom = atom<string | null>(null)
export const paymentsAtom = atom<Payment[]>([])
export const stepScanAtom = atom<StepScanAtom>({
  step: EStepScan.SCAN,
  idPayment: null,
  scanData: null,
})

