import { useMemo } from 'react'
import { isURL } from '../utils/isURL'

enum ETypePayment {
  NSPK = 'NSPK',
  MULTIQR = 'MULTIQR',
  PLATIQR = 'PLATIQR'
}

const hosts = {
  [ETypePayment.NSPK]: 'qr.nspk.ru',
  [ETypePayment.MULTIQR]: 'multiqr.ru',
  [ETypePayment.PLATIQR]: 'platiqr.ru',
}

const amountParamNames = {
  [ETypePayment.NSPK]: 'sum',
  [ETypePayment.MULTIQR]: 'amount',
  [ETypePayment.PLATIQR]: 'amount'
}

export const hostsArray = Object.values(hosts)

export const useQRMeta = (url: string | null) => {
  const checkUrl = (url: string) => {
    try {
      if (!isURL(url)) return false
      const parsedUrl = new URL(url)
      return hostsArray.includes(parsedUrl.hostname)
    } catch (e) {
      console.log(e)
    }
  }

  const parsedUrl = useMemo(() =>  {
    if (url && isURL(url)) {
      return new URL(url)
    }
    return null
  }, [url])

  const hostToPaymentType = Object.fromEntries(
    Object.entries(hosts).map(([key, value]) => [value, key])
  )

  const typePayment = useMemo(() => {
    if (parsedUrl) {
      return hostToPaymentType[parsedUrl.hostname] as ETypePayment || null;
    }
    return null
  }, [hostToPaymentType, parsedUrl])

  const amount = useMemo(() => {
    if (parsedUrl && typePayment) {
      const paramName = amountParamNames[typePayment];
      return paramName ? parsedUrl.searchParams.get(paramName) : null;
    }
    return null;
  }, [parsedUrl, typePayment])


  return {
    checkUrl,
    typePayment,
    amount
  }
}
