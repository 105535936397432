import { Box, Button, Heading, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { InputMask, phoneNumberMask } from '../components/InputMask'
import { useAtom } from 'jotai'
import { phoneAtom } from '../atoms/atoms'
import { conformToMask } from 'react-text-mask'

export const Main = () => {
  const [value, setValue] = useState('')
  const navigate = useNavigate()
  const [phone, setPhone] = useAtom(phoneAtom)

  const isDisabled = value === '' && value?.length < 10

  const conformedPhoneNumber = conformToMask(value ?? '', phoneNumberMask, {
    guide: true,
  })

  const savePhone = () => {
    localStorage.setItem('phone', value)
    setPhone(value)
  }

  const handleScanClick = () => {
    savePhone()
    navigate('scan')
  }

  const handlePaymentsClick = () => {
    savePhone()
    navigate('payments')
  }

  useEffect(() => {
    if (phone) {
      setValue(phone)
    }
  }, [phone])

  return (
    <Stack flex={1} spacing="24px" justifyContent="center">
      <Stack
        spacing="30px"
        borderRadius="18px"
        backgroundColor="gray.700"
        padding="38px"
      >
        <Stack textAlign="center" spacing="8px">
          <Heading size="lg">Пап, купи!</Heading>
          <Box>Введите свой номер телефона или номер телефона папы</Box>
        </Stack>
        <InputMask
          name="phone"
          value={conformedPhoneNumber.conformedValue}
          onChange={e => setValue(e.replace(/\D/g, ''))}
        />
        <Stack spacing="14px">
          <Button
            isDisabled={isDisabled}
            size="lg"
            colorScheme="orange"
            borderRadius="full"
            onClick={handleScanClick}
          >
            Я ребенок
          </Button>
          <Button
            isDisabled={isDisabled}
            size="lg"
            colorScheme="cyan"
            borderRadius="full"
            onClick={handlePaymentsClick}
          >
            Я отец
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
