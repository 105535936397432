import React, { ReactNode } from 'react'
import { extendTheme, ChakraProvider } from '@chakra-ui/react'
import { buttonTheme } from './components/Button'
import { menuTheme } from './components/Menu'

export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        minWidth: '360px',
        height: '100%',
        width: '100%',
        //backgroundColor: '#eff1f3',
      },
    },
  },
  radii: {
    '2xl': '1rem',
    '3xl': '1.5rem',
    base: '0.25rem',
    full: '9999px',
    lg: '0.8rem',
    md: '0.5rem',
    none: '0',
    sm: '0.125rem',
    xl: '0.9rem',
  },
  components: {
    Button: buttonTheme,
    Menu: menuTheme,
  },
  colors: {
    purple: {
      50: '#EDE4FF',
      100: '#E2D3FF',
      200: '#CBB0FF',
      300: '#B28AFF',
      400: '#9C6AFF',
      500: '#8447FC',
      600: '#7330F6',
      700: '#6528DC',
      800: '#531BC1',
      900: '#41149A',
    },
  },
  config: {
    initialColorMode: 'light',
  },
})

interface ThemeProps {
  children: ReactNode
}

export const Theme: React.FC<ThemeProps> = ({ children }) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>
}
