import { useAtomValue, useSetAtom } from 'jotai'
import { paymentsAtom, phoneAtom } from '../atoms/atoms'
import { api } from '../api'

export const usePayments = () => {
  const phone = useAtomValue(phoneAtom)
  const setPayments = useSetAtom(paymentsAtom)

  const fetchPayments = async () => {
    if (phone && phone !== '') {
      const result = await api.fetchPayments(phone)
      setPayments(result)
    }
  }

  return {
    fetchPayments
  }
}
