import {
  InputGroup,
  FormControl, InputLeftAddon, Input,
} from '@chakra-ui/react'
import MaskedInput from 'react-text-mask'
import React from 'react'

export const phoneNumberMask = [
  '(',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ')',
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
]

interface InputMaskProps {
  name: string
  onChange: (value: string) => void
  leftAddon?: string
  value: string
}

export const InputMask: React.FC<InputMaskProps> = (
  {
    name,
    onChange,
    leftAddon,
    value,
    ...props
  }
) => {
  return (
    <FormControl>
      <InputGroup size="lg">
        {leftAddon && <InputLeftAddon children={leftAddon} />}
        <MaskedInput
          mask={phoneNumberMask}
          id={name}
          value={value}
          render={(ref, props) => (
            <Input
              id={name}
              ref={ref as React.Ref<HTMLInputElement>}
              fontSize="1rem"
              placeholder={'(000) 000-00-00'}
              errorBorderColor="red.300"
              _focusVisible={{
                zIndex: 1,
                borderColor: 'gray.400',
                boxShadow: '0 0 0 1px var(--chakra-colors-gray-400)',
              }}
              {...leftAddon && {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              {...props}
            />
          )}
          onChange={(e) => onChange(e.target.value)}
          {...props}
        />
      </InputGroup>
    </FormControl>
  )
}
