import { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useWindowHeight = () => {
  const location = useLocation()
  const [height, setHeight] = useState(window.innerHeight)
  const [videoHeight, setVideoHeight] = useState(460 - 40)

  const handleResize = () => {
    setTimeout(() => {
      setHeight(window.innerHeight)
      setVideoHeight((window.innerWidth > 460 ? 460 : window.innerWidth) - 40)
    }, 400)
  }

  useEffect(() => {
    handleResize()
  }, [location?.pathname])

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { height, videoHeight }
}
